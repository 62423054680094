<template>
  <div class="up">
    <section class="team-section container">
      <div class="content-wrapper">
        <h1>
          {{ $t("teamSection.team") }}
        </h1>

        <div class="team-list">
          <TeamMember
            v-for="(teamMember, index) in teamMembers"
            :key="index"
            :name="teamMember.name"
            :post="teamMember.post"
            :linkedin="teamMember.linkedin"
          />
        </div>

        <h1>
          {{ $t("teamSection.advisers") }}
        </h1>

        <div class="team-list">
          <TeamMember
            v-for="(teamMember, index) in adviserMembers"
            :key="index"
            :name="teamMember.name"
            :post="teamMember.post"
            :linkedin="teamMember.linkedin"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import { ref } from "vue";

import TeamMember from "@/components/TeamMember.vue";
const teamMembers = ref([
  {
    name: "Ilya Ermakov",
    post: "CEO",
    linkedin: "https://www.linkedin.com/in/ilya-ermakov-aut/"
  },
  {
    name: "Alexander Ivanisov",
    post: "CTO",
    linkedin: "https://www.linkedin.com/in/alexander-ivanisov/"
  },
  {
    name: "Artem Fominykh",
    post: "CBDO",
    linkedin: "https://www.linkedin.com/in/artem-fominykh-326959162/"
  },
  {
    name: "Alex Zarya",
    post: "CMO",
    linkedin: "https://www.linkedin.com/in/alexey-zarya/"
  },
  {
    name: "Igor Yakovlev",
    post: "CLO"
  },
  {
    name: "Vladislav Babayants",
    post: "CCO"
  },
  {
    name: "Dan Goldberg",
    post: "Head of SMM"
  },
  {
    name: "Daniel Novichkov",
    post: "Product manager"
  }
]);

const adviserMembers = ref([
  {
    name: "Sergei Rodin",
    post: "Adviser"
  },
  {
    name: "Dmitriy Lyamenkov",
    post: "Adviser",
    linkedin: "https://www.linkedin.com/in/dmitry-lyamenkov/"
  },
  {
    name: "Vasiliy Danilov",
    post: "Adviser",
    linkedin: "https://www.linkedin.com/in/vndanilove/"
  },
  {
    name: "Alex Minakov",
    post: "Adviser",
    linkedin: "https://www.linkedin.com/in/alexminakov/"
  },
  {
    name: "Alexander Shishow",
    post: "Adviser",
    linkedin: "https://www.linkedin.com/in/alexandershishow/"
  }
]);

</script>

<style lang="scss" scoped>

.team {
  &-section {
    display: flex;
    justify-content: center;
    align-items: center;
    .content-wrapper {
      color: #1f3a43;

      @media screen and (max-width: 1200px) {
        overflow-x: auto;
        overflow-y: hidden;
      }

      h1 {
        text-align: center;
        width: 100%;
        font-size: 68px;
        margin-bottom: 30px;

        &:not(:first-child){
          margin-top: 70px;
        }
      }

      p {
        max-width: 1066px;
        font-weight: 500;
        font-size: 28px;
        margin-bottom: 97px;
        line-height: 38px;
      }
    }
  }
  &-list {
    display: flex;
    column-gap: 31px;
    row-gap: 81px;
    flex-wrap: wrap;
    justify-content: center;
  }
}
.leader {
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
  column-gap: 31px;
}
</style>
